import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import init from './utils/init'
import './config/lazy_use'
import i18n from './locales'
// import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import apis from './utils/api/account.js'
Vue.prototype.$apis = apis
Vue.config.productionTip = false

// 自定义指令
import './utils/directives/index';

import './global.less'
// Vue.component('pro-layout', ProLayout)
// Vue.component('page-header-wrapper', PageHeaderWrapper)

new Vue({
  router,
  store,
	i18n,
	created: init,
  render: h => h(App)
}).$mount('#app')
