<template>
<div class="main-box">

</div>
</template>

<script>
// import { getSmsCaptcha } from '@/api/login'
export default{
	data() {
		return {
			forgetForm: {},
			passwordForm: {
				password: ''
			},
			sign: '',
			state: {
			  time: 60,
				signBtn: false,
			  smsSendBtn: false,
			  submitBtn: false,
			}
		}
	},
	methods: {
		handlePasswordLevel (rule, value, callback) {
		  let level = 0
		  // 判断这个字符串中有没有数字
		  if (/[0-9]/.test(value)) level++
		  // 判断字符串中有没有字母
		  if (/[a-zA-Z]/.test(value)) level++
		  if (level >= 2 && value.length>=8) callback()
		  else callback(new Error('长度8-20位，同时包含大小写字母、数字'))
		},
		next() {  // 重置
			this.$refs.carousel.next()
		},
		prev() {  // 返回邮箱验证
			this.$refs.carousel.prev()
		},
		getCaptcha(formName) {  // 获取邮箱验证码
			const { state, forgetForm, $message, $notification } = this
			this.$refs[formName].validateField('email',err => {
				if (!err) {
					state.smsSendBtn = true
					const interval = window.setInterval(() => {
					  if (state.time-- <= 0) {
					    state.time = 60
					    state.smsSendBtn = false
					    window.clearInterval(interval)
					  }
					}, 1000)
					const hide = $message.loading('验证码发送中..', 0)
					this.$apis.getForgotCode({ account: forgetForm.email, type: 'email' }).then(res => {
					  setTimeout(hide, 1000)
					  $notification['success']({
					    message: '提示',
					    description: res.msg,
					    duration: 8
					  })
					}).catch(err => {
					  setTimeout(hide, 1)
					  clearInterval(interval)
					  state.time = 60
					  state.smsSendBtn = false
						$notification['error']({
						  message: '错误',
						  description: err.msg || '请求出现错误，请稍后再试',
						  duration: 4
						})
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		onStar(formName) {
			this.state.signBtn = true
			this.$refs[formName].validate(valid => {
				if(valid) {
					let params = { account: this.forgetForm.email, type: 'email', msgCode: this.forgetForm.msgCode }
					this.$apis.forgot(params).then(res => {
						this.sign = res.data.token
						this.$notification['success']({
						  message: '提示',
						  description: res.msg,
						  duration: 8
						})
						this.next()
						this.forgetForm.msgCode = ''
					}).catch(err => {
						this.$notification['error']({
						  message: '提示',
						  description: err.msg,
						  duration: 8
						})
					}).finally(() => {this.state.signBtn = false})
				}
				else {
					setTimeout(() => {
					  this.state.signBtn = false
					}, 500)
				}
			})
		},
		onSubmit(formName) {
			this.state.submitBtn = true
			this.$refs[formName].validate(valid => {
				if(valid) {
					let params = {
						account: this.forgetForm.email,
						passWord: this.passwordForm.password,
						type: 'email',
						signature: this.sign
					}
					this.$apis.setPassword(params).then(res => {
						this.$notification['success']({
						  message: '提示',
						  description: res.msg,
						  duration: 8
						})
						this.$router.push({ path: '/' })
					}).catch(err => {
						this.$notification['error']({
						  message: '提示',
						  description: err.msg,
						  duration: 8
						})
					}).finally(() => {
						this.passwordForm.password = ''
						this.state.submitBtn = false
					})
				} else {
          setTimeout(() => {
            this.state.submitBtn = false
          }, 600)
        }
			})
		},
	}
}
</script>

<style lang="less" scoped>
.main-box{
	width: 100%;
	.carousel-item-box{
		height: calc(100vh - 112px);
		display: flex !important;
		justify-content: center;
		.content-box{
			width: 420px;
			height: 100%;
			display: flex;
			flex-direction: column;
			.flex-box{
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-bottom: 75px;
			}
			.footer-box{
				width: 100%;
				padding: 24px;
				text-align: center;
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #111111;
				letter-spacing: 1px;
				.btn-link{
					color: #0078FF;
					cursor: pointer;
				}
			}
		}
		.page-title{
			font-size: 28px;
			font-family: PingFang SC;
			font-weight: 800;
			line-height: 40px;
			color: #111111;
			margin-bottom: 8px;
		}
		.noce{
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #333C54;
			margin-bottom: 32px;
		}
	}
}
button.submit-button {
	padding: 0 15px;
	font-size: 16px;
	height: 40px;
	width: 100%;
}
</style>
