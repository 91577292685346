import request from './request'

const apis = {
	// 获取封面图 
	getCoverPicture: (data={}) => {return request({url: 'auth/getCoverPicture',method: 'post',data: data})},
	// 注册
	register: (data={}) => {return request({url: 'auth/register',method: 'post',data: data})},
	// 隐私协议
	privacy: (data={}) => {return request({url: 'auth/privacy',method: 'post',data: data})},
	// 登录
	login: (data={}) => {return request({url: 'auth/login',method: 'post',data: data})},
	// 退出登录
	loginout: (data={}) => {return request({url: 'auth/loginout',method: 'post',data: data})},
	// 获取验证码
	getSmsCaptcha: (data={}) => {return request({url: 'auth/getRegisterCode',method: 'post',data: data})},
	// 获取账户名下店铺信息
	getMyStore: (data={}) => {return request({url: 'store/myStore',method: 'post',data: data})},
	// 忘记密码-获取验证码
	getForgotCode: (data={}) => {return request({url: 'auth/getForgotCode',method: 'post',data: data})},
	// 忘记密码
	forgot: (data={}) => {return request({url: 'auth/forgot',method: 'post',data: data})},
	// 修改密码
	setPassword: (data={}) => {return request({url: 'auth/setPassword',method: 'post',data: data})},
	// 重置密码
	reset: (data={}) => {return request({url: 'auth/reset',method: 'post',data: data})},
	// 获取店铺配置数据
	getStoreConfig: (data={}) => {return request({url: 'store/getStoreConfig',method: 'post',data: data})},
	// 创建店铺 store/create
	create: (data={}) => {return request({url: 'store/create',method: 'post',data: data})},
}

export default apis;

