import xiaoge from './xiaoge.js';
import md5 from './md5.js';
import storage from 'store';
import {ACCESS_TOKEN} from '@/store/mutation-types';

/**
 * 生成随机字符串
 * @param {Object} n
 */
function random_char(n) {
    let random_charset = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    let res = '';
    for (let i = 0; i < n; i++) {
        let id = Math.ceil(Math.random() * 35);
        res += random_charset[id];
    }
    return res;
}

/**
 * 把字符串中的汉字转换成Unicode
 * @param {Object} str
 */
function ch2Unicdoe(str) {
    if (!str) return;
    var unicode = '';
    for (var i = 0; i < str.length; i++) {
        var temp = str.charAt(i);
        if (/[\u4e00-\u9fa5]/.test(temp)) {
            unicode += '\\u' + temp.charCodeAt(0).toString(16);
        } else {
            unicode += temp;
        }
    }
    return unicode;
}

/**
 * @param {Object} data
 * @param {Object} key
 */
function sortKeys(data, key) {
    let newkey = Object.keys(data).sort();
    let sign = 'xiaoge';
    let newObj = {};
    for (let i = 0; i < newkey.length; i++) {
        let str = typeof data[newkey[i]] == 'string' ? String(data[newkey[i]]) : JSON.stringify(data[newkey[i]]);
        if (str) {
            newObj[newkey[i]] = str;
            sign += md5(str + key);
        }
    }
    newObj.sign = md5(sign);
    // console.log('数据加密',newObj)
    return newObj;
}

/**
 * 获取最新的时间 封装好
 */
function getNewTime() {
    return new Date().getTime();
}


/**
 * 加密 sign
 */
export function encryptedData(data) {
    const encryptor = new xiaoge();
    encryptor.setPublicKey(
        `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCgTAigSnYPThZ1B16Zm40jAaGy
ExQIfkc03KHJfgPVn19P04hFmmnnEsNmGnmUcnQkROUHzJyfHTywJWNoHn+H37Jo
2wSQDABSIoaXdiEsZpq26WyGbfWgGnJUfbbSzOQEV6oH+bKaKyc9wZ3sDm+qJ96L
G7DsUFj+65wg2RhhwwIDAQAB`
    );
    return encryptor.encryptLong(JSON.stringify(data));
}

/**
 * 解密
 */
export function decryptedData(data) {
    const encryptor = new xiaoge();
    encryptor.setPrivateKey(
        `MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBALyf3BMWJ/ZZUYbt
gUoTXB8sIFUFYPr7XJEtmfg9U6vYqqFFYxDHDK3m/cXWSlahP619zbPxkGvFndTq
/0WRkE7DAb1UZTicwcxtLQAhTa6E025tJseCDn/WLDR/Wem7pKMZDZ5mvZGO8qq7
piEY6Tor8Qloj9Ligj1LMnR0LexxAgMBAAECgYBmkXMFefXPErCvczTrx+0/Eiqe
sOcUc1zZoPdpskduWconGekNtX9ijQoP6z/PLa3HtHIH2GKVCAeUQRkAhm/2RSdu
18OVGndiCMD6XPjgH655AA3FY6j5jW3iC9wkQ+Y+HKwD4Z4WR5XmPK4JTU0D8DXW
y9/05iN/p/jh9YemsQJBAOHJ4YgE4k5128mr3M96OKbOBe7bA3OUMylxiMNoTjz5
gDXagBvHlxRYwRd7UTu5xOqmo2ql98ktHex2CZHk830CQQDV3PcYKraB7bAziND7
cTIuDk+VjtQHl+E8FouCt+L/4BJdr3YmdE1DsjOVo+Ba4Xqa69sQa4rB65cuWAGk
W8cFAkEA3k/4UAT/DmHdVmRxtZeR0CtqjBP5AgQZm+S91CkPX9oJ8vyAxtluWApL
lONGyJfQ23nXzVo870gM/pX6NjxHwQJAP+nzprNNK/NfD2e6HQstVfpM6oNu9n37
gDu0ofJW0uWiV4FSrBdh5/Nf2z7IlCCCxb3rEDMzmtTPr7f5dadACQJATQO271Io
TL6aMrAjhrriM7KF6MUaVHwQf0mCmVzpLiVVY7nY9O6PwbLYIBK+PRMQSW0elK+l
9zyA8zuYrsXF+Q==`
    );
    return encryptor.decryptLong(data);
}

export default function sign(data) {
    const token = storage.get(ACCESS_TOKEN) || (process.env.VUE_APP_NO_TOKEN_SIGN ||"enfyMSjYFvAE15r8ZGBtgJ2XI49UoLND0HxqQwPOic6TpaVdlCksb3zh7KmRuW3KQpSU6p0pZ5Ws51tBa0v8XsrmOyP55MhZbgJL")

    let randomChar = String(random_char(32));
    data.signEndTime = getNewTime(); // 请求发送时间 必须
    data.randomChar = randomChar;
    data.versionInfo = '1.0.00';
    return sortKeys(data, md5(randomChar + data.signTime + window.location.host + token + (process.env.VUE_APP_SIGN_KEY || "jPi8c9T2azuVAvrypewWbqZh1Nl035HESmBLsgRXfKQCDxJoF7IktU4d6MGOnYmSDSDQ981r9rJSxQqmVWjMZhBQhOHdEAtjMOMB")));


}
