<template>
  <div class="main user-layout-register">

  </div>
</template>

<script>
import storage from 'store'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
// import { getSmsCaptcha, register } from '@/api/account'
export default {
  name: 'Register',
  data () {
    return {
      form: this.$form.createForm(this),
      isAgree: false,
			state: {
        time: 60,
        smsSendBtn: false,
      },
      registerBtn: false,
			visible: false,
			contract: {  // 平台合同
				title: "平台合同",
				content: ''
			},
			agreement: {  // 用户协议
				title: "用户协议",
				content: ''
			},
			privacy: {  // 隐私政策
				title: "隐私政策",
				content: ''
			},
			document: {}
    }
  },
	created() {
		var sign = this.getQueryVariable('token')
    console.log('sign:',sign)
    if(sign) this.$router.replace({path: '/account/login',query: {token: sign}})
	},
	mounted() {
		this.getPrivacy()
	},
  methods: {
		getQueryVariable(variable) {
			var query = window.location.search.substring(1);
			var vars = query.split("&");
			for (var i = 0; i < vars.length; i++) {
				var pair = vars[i].split("=");
				if (pair[0] == variable) {
					return pair[1];
				}
			}
			return (false);
		},
		getPrivacy() {  // 获取隐私协议
			this.$apis.privacy().then(res => {
				// console.log('隐私协议',res)
				this.agreement.content =  res.data.account_user_agreement.content || ''
				this.privacy.content =  res.data.account_privacy_policy.content || ''
				this.contract.content = res.data.account_platform_contract.content || ''
			}).catch(err => {
				this.$notification['error']({
					message: '提示',
					description: err.msg,
					duration: 5
				})
			})
		},
		opDoc(key) {  // 打开平台文档
			this.document = this[key]
			this.visible = true
		},
		onOk(){
			this.isAgree = true
			this.visible = false
		},
		
    handlePasswordLevel (rule, value, callback) {
      let level = 0
      // 判断这个字符串中有没有数字
      if (/[0-9]/.test(value)) level++
      // 判断字符串中有没有字母
      if (/[a-zA-Z]/.test(value)) level++
      if (level >= 2 && value.length>=8) callback()
      else callback(new Error('长度8-20位，同时包含字母、数字'))
    },
		confirmationPassword(rule, value, callback) {
      const form = this.form;
			if (value && value !== form.getFieldValue('password')) {
				callback('两次输入的密码不一致!');
			} else {
				callback();
			}
    },
    handleSubmit () {
			this.registerBtn = true
      const { form: { validateFields }, state, $router } = this
			validateFields({ force: true }, (err, values) => {
				if (!err) {
					// $router.push({ name: 'registerResult', params: { ...values } })
					const params = {
						account: values.email,
						type: 'email',
						msgCode: values.captcha,
						password: values.password,
						password_confirmation: values.password_confirmation,
					}
					this.$apis.register(params)
						.then(res => {
							console.log('注册试用：',res)
							// this.$router.push({ path: '/account/login' })
							setTimeout(() => {
								let url = res.data.siteDomain + '?' + 'sign=' + res.data.bsToken
								window.location.href = url
							},1000)
							this.$notification['success']({
								message: '提示',
								description: res.msg,
								duration: 5
							})
						})
						.catch(err => {
							this.$notification['error']({
								message: '提示',
								description: err.msg,
								duration: 5
							})
						})
						.finally(() => {this.registerBtn = false})
				}else {
					let that = this
					setTimeout(() => {
						that.registerBtn = false
					}, 600)
				}
			})
    },

    getCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state, $message, $notification } = this

      validateFields(['email'], { force: true },
        (err, values) => {
          if (!err) {
            state.smsSendBtn = true

            const interval = window.setInterval(() => {
              if (state.time-- <= 0) {
                state.time = 60
                state.smsSendBtn = false
                window.clearInterval(interval)
              }
            }, 1000)

            const hide = $message.loading('验证码发送中..', 0)

            this.$apis.getSmsCaptcha({ account: values.email, type: 'email' }).then(res => {
              setTimeout(hide, 500)
              $notification['success']({
                message: '提示',
                description: res.msg,
                duration: 8
              })
            }).catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 60
              state.smsSendBtn = false
              this.$notification['error']({
                message: '错误',
                description: err.msg,
                duration: 4
              })
							this.registerBtn = false
            })
          }
        }
      )
    },
  }
}
</script>
<style lang="less">
  .user-register {

    &.error {
      color: #ff0000;
    }

    &.warning {
      color: #ff7e05;
    }

    &.success {
      color: #52c41a;
    }

  }

  .user-layout-register {
    .ant-input-group-addon:first-child {
      background-color: #fff;
    }
  }
</style>
<style lang="less" scoped>
	.main{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		.flex-box{
			width: 100%;
			flex: 1;
			padding-bottom: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			.form-box{
				width: 408px;
			}
		}
	}
	.page-title{
		display: flex;
		align-items: flex-end;
		height: 76px;
		margin-bottom: 24px;
		.span1{
			font-size: 86px;
			font-family: Roboto;
			font-weight: bold;
			line-height: 76px;
			color: #0078FF;
		}
		.span2{
			font-size: 32px;
			font-family: PingFang SC;
			font-weight: bold;
			line-height: 42px;
			margin-left: 10px;
			color: #111111;
		}
	}
	.agreement-box{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		p{
			margin-bottom: 0;
			margin-left: 8px;
			font-size: 12px;
			color: #111111;
			span{
				color: #0078FF;
				cursor: pointer;
			}
		}
	}
	.footer-box{
		width: 100%;
		padding: 24px;
		text-align: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #111111;
		letter-spacing: 1px;
		.btn-link{
			color: #0078FF;
			cursor: pointer;
		}
	}
	/deep/.ant-modal-body{
		padding: 12px;
	}
	.agreement-text{
		width: 100%;
		height: 55vh;
		overflow-y: auto;
		background-color: #FFFFFF;
		padding: 8px 16px;
		&::-webkit-scrollbar {/*滚动条整体样式*/
			width: 5px;     /*高宽分别对应横竖滚动条的尺寸*/
			height: 1px;
		}
		&::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
			background: #e6e6e6;
		}
		&::-webkit-scrollbar-track {/*滚动条里面轨道*/
			-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
			border-radius: 10px;
			background: #ffffff;
		}
	}
	
  .user-layout-register {

    & > h3 {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .getCaptcha {
      display: block;
      width: 100%;
      height: 40px;
    }

    .register-button {
      padding: 0 15px;
      font-size: 16px;
      height: 40px;
      width: 100%;
    }

    .login {
      float: right;
      line-height: 40px;
    }
  }
</style>
