<template>
<div class="main-box">

</div>
</template>

<script>
import storage from 'store'
import { mapGetters } from 'vuex'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
// import { getSmsCaptcha } from '@/api/login'
export default{
	data() {
		return {
			account: '353968169@qq.com',
			token: '',
			resetForm: {
				oldPassword: '',
				newPassword: ''
			},
			loginForm: {},
			state: {
			  time: 60,
			  submitBtn: false,
			  smsSendBtn: false
			}
		}
	},
	computed: {
		// ...mapGetters(['token'])
	},
	mounted() {
		console.log(this.$route.query)
	},
	created() {
		if(this.$route.query.account) this.account=this.$route.query.account
		if(this.$route.query.r_token) this.token=this.$route.query.r_token
		// else this.$router.push({ path: '/' })
	},
	methods: {
		handlePasswordLevel (rule, value, callback) {
		  let level = 0
		  // 判断这个字符串中有没有数字
		  if (/[0-9]/.test(value)) level++
		  // 判断字符串中有没有字母
		  if (/[a-zA-Z]/.test(value)) level++
		  if (level >= 2 && value.length>=8) callback()
		  else callback(new Error('长度8-20位，同时包含字母、数字'))
		},
		next() {  // 重置
			this.$refs.carousel.next()
		},
		prev() {  // 返回邮箱验证
			this.$refs.carousel.prev()
		},
		getCaptcha(formName) {  // 获取邮箱验证码
			const { state, forgetForm, $message, $notification } = this
			this.$refs[formName].validateField('email',err => {
				if (!err) {
					state.smsSendBtn = true
					const interval = window.setInterval(() => {
					  if (state.time-- <= 0) {
					    state.time = 60
					    state.smsSendBtn = false
					    window.clearInterval(interval)
					  }
					}, 1000)
					const hide = $message.loading('验证码发送中..', 0)
					this.$apis.getSmsCaptcha({ mobile: forgetForm.email }).then(res => {
					  setTimeout(hide, 1000)
					  $notification['success']({
					    message: '提示',
					    description: '验证码获取成功，您的验证码为：' + res.result.captcha,
					    duration: 8
					  })
					}).catch(err => {
					  setTimeout(hide, 1)
					  clearInterval(interval)
					  state.time = 60
					  state.smsSendBtn = false
						$notification['error']({
						  message: '错误',
						  description: err.msg || '请求出现错误，请稍后再试',
						  duration: 4
						})
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		onStar(formName) {
			this.state.submitBtn = true
			this.$refs[formName].validate(valid => {
				if(valid) {
					let params = {
						account: this.account,
						type: 'email',
						oldPassword: this.resetForm.oldPassword,
						passWord: this.resetForm.newPassword,
						signature: this.token
					}
					this.$apis.reset(params).then(res => {
						this.$notification['success']({
						  message: '提示',
						  description: res.msg,
						  duration: 8
						})
						this.next()
						this.resetForm = {}
						this.loginForm = {}
					}).catch(err => {
						this.$notification['error']({
						  message: '提示',
						  description: err.msg || '网络出现异常，请稍后再试',
						  duration: 4
						})
					}).finally(() => {this.state.submitBtn = false})
				} else {
          setTimeout(() => {
            this.state.submitBtn = false
          }, 600)
        }
			})
		},
		onSubmit(formName) {
			this.state.submitBtn = true
			this.$refs[formName].validate(valid => {
				if(valid) {
				  const loginParams = {
						account: this.account,
						passWord: this.loginForm.password,
						type: 'email'
					}
				  this.$apis.login(loginParams)
				    .then((res) => this.loginSuccess(res))
				    .catch(err => this.requestFailed(err))
				    .finally(() => {this.state.submitBtn = false})
				} else {
				  setTimeout(() => {
				    this.state.submitBtn = false
				  }, 600)
				}
			})
		},
		loginSuccess (res) {  // 登录成功
			storage.set(ACCESS_TOKEN, res.data.token, 24 * 60 * 60 * 1000)
			storage.set(USER_INFO, res.data.userInfo, 24 * 60 * 60 * 1000)
			this.$store.commit('SET_TOKEN', res.data.token)
			this.$store.commit('SET_INFO', res.data.userInfo)
			this.$router.replace('login')
		  setTimeout(() => {
		    this.$notification.success({
		      message: '提示',
		      description: res.msg
		    })
		  }, 1000)
		},
		requestFailed (err) {  // 登录失败
		  this.$notification['error']({
		    message: '提示',
		    description: err.msg || '网络出现异常，请稍后再试',
		    duration: 4
		  })
		},
	}
}
</script>

<style lang="less" scoped>
.main-box{
	width: 100%;
	.carousel-item-box{
		height: calc(100vh - 112px);
		display: flex !important;
		justify-content: center;
		.content-box{
			width: 420px;
			height: 100%;
			display: flex;
			flex-direction: column;
			.flex-box{
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-bottom: 75px;
			}
			.footer-box{
				width: 100%;
				padding: 24px;
				text-align: center;
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #111111;
				letter-spacing: 1px;
				.btn-link{
					color: #0078FF;
					cursor: pointer;
				}
			}
		}
		.page-title{
			font-size: 28px;
			font-family: PingFang SC;
			font-weight: 800;
			line-height: 40px;
			color: #111111;
			margin-bottom: 8px;
		}
		.noce{
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #333C54;
			margin-bottom: 32px;
		}
	}
}
button.submit-button {
	padding: 0 15px;
	font-size: 16px;
	height: 40px;
	width: 100%;
}
.disInput{
	color: rgba(#000000,0.68);
}
.user-login-other {
	margin-top: 24px;
	line-height: 22px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>
