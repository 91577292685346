<template>
  <div class="main">
		<div class="flex-box" v-if="!token" style="padding-bottom: 135px;">
			<div class="page-title">登录易优购(新)</div>
			<a-form class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit" >
			  <a-form-item>
					<a-input size="large" type="text" placeholder="邮箱"
						v-decorator="[ 'username', {rules: [{ required: true, message: '请输入邮箱地址' }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'} ]"
					>
					</a-input>
			  </a-form-item>
			  <a-form-item>
					<a-input-password size="large" placeholder="密码"
						v-decorator="[ 'password', {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'change'} ]"
					>
					</a-input-password>
			  </a-form-item>
			  <a-form-item style="margin-top:24px">
			    <a-button size="large" type="primary" htmlType="submit" class="login-button" :loading="state.loginBtn" :disabled="state.loginBtn" >登录</a-button>
			  </a-form-item>
	
			</a-form>
		</div>
		<div class="logined-box" v-else>
			<a-carousel ref="carousel" :dots="false" effect="fade">
				<div class="content-box">
					<div>
						<div class="page-title">{{userName}}已登录</div>
						<p class="noce">你关联了多个商户主体，请选择一个进行店铺管理</p>
						<div class="store-box" v-if="!isLoading">
			
							<template v-for="item in storeList">
								<div class="store-item main-store" v-if="item.accountCategory == 1" @click="next(item)" :key="item.id">
									<div class="left">
										<img src="@/assets/images/user/store.png" width="100%" />
									</div>
									<div class="right">
										<div class="text">{{item.name}}</div>
										<div class="val">{{item.siteDomain}}</div>
										<div class="option">
											{{item.storeNum}}个店铺
											<a-icon type="right" />
										</div>
									</div>
								</div>
								<div class="store-item" v-else @click="next(item)" :key="item.id">
									<div class="left">
										<div class="text">{{item.name}}</div>
										<div class="val">{{item.siteDomain}}</div>
									</div>
									<div class="right option">
										{{(item.storeStatus || !item.userStatus)?'冻结':''}}
										<a-icon type="right" />
									</div>
								</div>
							</template>
						</div>
						<div class="store-box" v-else>
							<a-spin size="large" />
						</div>
					</div>
				</div>
			
			</a-carousel>
		</div>
  </div>
</template>

<script>
import storage from 'store'
import { mapGetters } from 'vuex'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { Config } from '@/config/defaultSettings.js'

export default {
  data () {
    return {
      loginBtn: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        smsSendBtn: false,
				creatBtn: false,
      },
			isLogined: false,
			
			// 已登录
			accountInfo: {},  // 账户信息
			userName: '',  // 用户名称（邮箱前缀）
			isLoading: false,
			storeList: [],  // 账户下店铺列表
			isBoss: false,  // 是否有Boss身份
			phoneForm: {  // 验证收据from数据
				phone: '',
				msgCode: ''
			},
			currencyList: [],
			scopeList: [],
			topDomain: '',
			creatForm: {
				domain: '',
				name: '',
				// currencyId: 1,
				// businessScopeId: 1
			}
    }
  },
	computed: {
		...mapGetters(['token', 'userInfo'])
	},
	created() {
		let signToken = this.getQueryVariable('token') || null
		// console.log('signToken:', signToken)
		if(signToken) {
      storage.remove(ACCESS_TOKEN)
      storage.remove(USER_INFO)
      this.$store.commit('SET_TOKEN', '')
      this.$store.commit('SET_INFO', {})
			this.$apis.login({signature: signToken}).then(res => {
				console.log(res)
				if(!res.data.userInfo.isActivation) this.$router.replace({path: '/account/reset',query: {account: res.data.userInfo.account || '',r_token: res.data.token}})
				else {
					this.isLogined = true
					// storage.set(ACCESS_TOKEN, res.data.token, 24 * 60 * 60 * 1000)
					// storage.set(USER_INFO, res.data.userInfo, 24 * 60 * 60 * 1000)
					// this.$store.commit('SET_TOKEN', res.data.token)
					// this.$store.commit('SET_INFO', res.data.userInfo)
					// this.getMyStore()
				}
			}).catch(err => {
				console.log('验证登录err:',err)
				this.$notification['error']({
					message: '提示',
					description: err.msg || '自动登录失败，请手动登录',
					duration: 8
				})
			})
		}
		else if(this.token) {
			this.userName = this.userInfo.account ? this.userInfo.account.split('@')[0] : ''
			// this.getMyStore()
		}
	},
  methods: {
		getQueryVariable(variable) {
			var query = window.location.search.substring(1);
			var vars = query.split("&");
			for (var i = 0; i < vars.length; i++) {
				var pair = vars[i].split("=");
				if (pair[0] == variable) {
					return pair[1];
				}
			}
			return (false);
		},
    // handler
    handleUsernameOrEmail (rule, value, callback) {  // 邮箱验证
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      callback()
    },
		getStoreConfig() {
			this.$apis.getStoreConfig({}).then(res => {
				this.topDomain = res.data.domainConfig.topDomain
				this.currencyList = res.data.currencyList
				this.scopeList = res.data.scopeList
			})
		},
    handleSubmit (e) {  // 登录
      e.preventDefault()
      const {form: { validateFields },state,Login} = this
      state.loginBtn = true
      const validateFieldsKey = ['username', 'password']
      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
					let browser = this.getBrowser()
					this.userName = values.username.split('@')[0]
          const loginParams = {
						account: values.username,
						passWord: values.password,
						type: 'email',
						version: Config.version,
						platform: 'pc',
						device_info: window.navigator.userAgent,
						browser: browser
					}
          this.$apis.login(loginParams)
            .then((res) => this.loginSuccess(res))
            .catch(err => this.requestFailed(err))
            .finally(() => {state.loginBtn = false})
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    loginSuccess (res) {  // 登录成功
				// 	let url = e.siteDomain + '?' + 'sign=' + e.bsToken
				window.location.href = res.data.store.siteDomain + '?' + 'sign=' + res.data.store.bsToken
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${res.data.userInfo.account}欢迎回来`
        })
      }, 1000)
    },
    requestFailed (err) {  // 登录失败
      this.$notification['error']({
        message: '提示',
        description: err.msg || '网络出现异常，请稍后再试',
        duration: 4
      })
    },

		
		async next(e) {  // 进入手机验证/进入BOSS管理后台
			// await this.getStoreConfig()
			// this.$refs.carousel.next()
			if(e.storeStatus===1) {
				this.$message.warning('店铺已冻结')
				return false
			}
			if(e.userStatus===0) {
				this.$message.warning('账户已冻结')
				return false
			}
			
			this.$apis.loginout().then(res => {
				// console.log('退出登录:',res)
				storage.remove(ACCESS_TOKEN)
				storage.remove(USER_INFO)
				this.$store.commit('SET_TOKEN', '')
				this.$store.commit('SET_INFO', {})
				let url = e.siteDomain + '?' + 'sign=' + e.bsToken
				window.location.href = url
			}).catch(err => {
				// this.$notification['error']({
				//   message: '提示',
				//   description: err.msg,
				//   duration: 4
				// })
			})
		},
		prev() {  // 返回商户选择
			this.$refs.carousel.prev()
			this.phoneForm = {}
		},
		onCreate(formName) {  // 开始试用
		
		},
		//获取浏览器名称
    getBrowser(){
      var browser = {
        msie: false,
        firefox: false,
        opera: false,
        safari: false,
        chrome: false,
        netscape: false,
        appname: 'unknown',
        version: 0
      },
      ua = window.navigator.userAgent.toLowerCase();
      if (/(msie|firefox|opera|chrome|netscape)\D+(\d[\d.]*)/.test(ua)) {
      browser[RegExp.$1] = true;
      browser.appname = RegExp.$1;
      browser.version = RegExp.$2;
      } else if (/version\D+(\d[\d.]*).*safari/.test(ua)) {
      // safari
      browser.safari = true;
      browser.appname = 'safari';
      browser.version = RegExp.$2;
      }
      return browser.appname;
    },
  }
}
</script>

<style lang="less" scoped>
.main{
	width: 100%;
	height: 100%;
	// padding-bottom: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex-box{
	width: 408px;
	.page-title{
		font-size: 28px;
		font-family: PingFang SC;
		font-weight: 800;
		line-height: 40px;
		color: #111111;
		margin-bottom: 24px;
	}
	.user-layout-login {
	  label {
	    font-size: 14px;
	  }
	
	  button.login-button {
	    padding: 0 15px;
	    font-size: 16px;
	    height: 40px;
	    width: 100%;
	  }
	
	  .user-login-other {
	    margin-top: 24px;
	    line-height: 22px;
			display: flex;
			align-items: center;
			justify-content: space-between;
	  }
	}
}

.logined-box{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	.content-box{
		width: 420px;
		height: calc(100vh - 112px);
		display: flex !important;
		flex-direction: column;
		justify-content: center;
		.page-title{
			font-size: 28px;
			font-family: PingFang SC;
			font-weight: 800;
			line-height: 40px;
			color: #111111;
			margin-bottom: 8px;
		}
		.noce{
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #333C54;
			margin-bottom: 40px;
		}
		.store-box{
			max-height: 420px;
			min-height: 272px;
			padding: 4px;
			padding-right: 8px;
			overflow: auto;
			&::-webkit-scrollbar {/*滚动条整体样式*/
				width: 5px;     /*高宽分别对应横竖滚动条的尺寸*/
				height: 1px;
			}
			&::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
				border-radius: 10px;
				box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
				background: #e6e6e6;
			}
			&::-webkit-scrollbar-track {/*滚动条里面轨道*/
				box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
				border-radius: 10px;
				background: #ffffff;
			}
			.add-btn{
				width: 100%;
				padding: 12px 18px;
				height: 56px;
				border-color: #2F82FF;
				border-radius: 5px;
				color: #2F82FF;
				margin-bottom: 32px;
			}
			.store-item{
				width: 100%;
				height: 105px;
				background: #FFFFFF;
				border: 1px solid #CCCCCC;
				border-radius: 4px;
				padding: 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;
				&.main-store{
					height: 139px;
					background: rgba(255, 206, 47, 0.08);
					border: 2px solid #FFCE2F;
					border-radius: 2px;
					align-items: flex-start;
					justify-content: flex-start;
					.left{
						width: 48px;
						height: 48px;
						border-radius: 50%;
						overflow: hidden;
						margin-right: 24px;
						margin-top: 8px;
					}
					.right{
						width: calc(100% - 72px);
						padding-right: 10px;
						.text{
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 32px;
							color: #333C54;
						}
						.val{
							font-size: 18px;
							font-family: PingFang SC;
							font-weight: bold;
							line-height: 32px;
							color: #130F26;
							overflow:hidden;
							white-space:nowrap;
							text-overflow:ellipsis;
						}
						.option{
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 32px;
							color: #333C54;
						}
					}
				}
				&+.store-item{
					margin-top: 20px;
				}
				.left{
					max-width: 310px;
					.text{
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 32px;
						color: #999999;
					}
					.val{
						font-size: 18px;
						font-family: PingFang SC;
						font-weight: bold;
						line-height: 32px;
						color: #333C54;
						overflow:hidden;
						white-space:nowrap;
						text-overflow:ellipsis;
					}
				}
				.right.option{
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #999999;
				}
			}
		}
		.getCaptcha {
      display: block;
      width: 100%;
      height: 40px;
    }
		button.login-button {
		  padding: 0 15px;
		  font-size: 16px;
		  height: 40px;
		  width: 100%;
		}
		.form-box{
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.footer-box{
			width: 100%;
			padding: 24px;
			text-align: center;
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #111111;
			letter-spacing: 1px;
			.btn-link{
				color: #0078FF;
				cursor: pointer;
			}
		}
	}
	.ant-carousel{
		width: 420px;
		display: flex;
		align-items: center;
		/deep/.slick-slider{
			width: 100%;
		}
	}
}
</style>
