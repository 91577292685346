<template>
	<div id="userLayout" class="user-layout-wrapper">
		<div class="container">
			<!-- <div class="left img-bg" :style="{ 'backgroundImage': 'url('+ picture +')'}"></div> -->
			<div class="left img-bg">
				<img :src="picture" />
			</div>
			<div class="right">
				<div class="header-nav">

					<!-- <select-lang :class="prefixCls" /> -->
					<avatar-dropdown v-if="token && isShowAvatar" :menu="false" :current-user="currentUser" class="user-avatar" />
				</div>
				<div class="content-box">
					<router-view />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex'
	import AvatarDropdown from '@/components/GlobalHeader/AvatarDropdown'
	import SelectLang from '@/components/SelectLang'

	export default {
		name: 'UserLayout',
		components: {AvatarDropdown,SelectLang},
		data() {
			return {
				picture: '',  // 封面图
				defaultPic: require('@/assets/images/user/cover.jpg'),
				currentUser: {},
				prefixCls: 'ant-pro-global-header-index-action',
			}
		},
		computed: {
			...mapGetters(['token','userInfo']),
			isShowAvatar() {
				let route = this.$route.name
				if(route == "reset") return false
				return true
			},
		},
		watch: {
			token(n,o) {
				if(n) {
					let name = this.userInfo.account ? this.userInfo.account.split('@')[0] : ''
					setTimeout(() => {
					  this.currentUser = {name: name}
					}, 1000)
				}
				else {
					this.currentUser = {name: ''}
				}
			}
		},
		created() {
			this.getPic()
			console.log(this.$route)
		},
		mounted() {
			document.body.classList.add('userLayout')
			let name = this.userInfo.account ? this.userInfo.account.split('@')[0] : ''
			setTimeout(() => {
			  this.currentUser = {name: name}
			}, 1000)
		},
		beforeDestroy() {
			document.body.classList.remove('userLayout')
		},
		methods: {
			setLang (lang) {  // 修改语言
			  // this.$store.dispatch('setLang', lang)
			},
			goLink(link) {
				window.open(link);
			},
			getPic() {
				this.$apis.getCoverPicture().then(res => {
					// console.log('res',res)
					this.picture = res.data.picture
				}).catch(err => {
					this.picture = this.defaultPic
				})
			}
		}
	}
</script>


<style lang="less" scoped>
  
  .img-bg {
    background-size: auto 100%;
    // background-color: #b6bfc4;
  }
  
	#userLayout.user-layout-wrapper {
		height: 100%;
		.container {
			width: 100%;
			height: 100%;
			background-color: #FFFFFF;
			display: flex;
			.left {
				flex: 1;
				height: 100%;
				&.img-bg{
					width: 100%;
					height: 100%;
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
			.right {
				width: 40%;
				min-width: 420px;
				padding: 40px;
				display: flex;
				flex-direction: column;
				.header-nav{
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					.user-avatar{
						display: flex;
						align-items: center;
						height: 32px;
						margin-left: 12px;
						cursor: pointer;
						/deep/.ant-avatar-image{
							margin-right: 5px;
						}
					}
					.ant-btn-link{
						color: #111111;
						padding: 0 10px;
					}
					/deep/.ant-select{
						.ant-select-selection{
							border: none;
							min-width: 92px;
							.ant-select-selection__rendered{
								line-height: 32px;
								.ant-select-selection-selected-value{
									float: right;
									color: #111111;
								}
							}
						}
					}
				}
				.content-box{
					width: 100%;
					flex: 1;
				}
			}
		}
		&.mobile {
			.container {
				.left{
					display: none;
				}
				.right {
					width: 100%;
				}
			}
		}
	}
</style>
