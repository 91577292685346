import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { Config } from '@/config/defaultSettings.js'
import sign from '../encrypt/encryption.js';
import {decryptedData, encryptedData} from '../encrypt/encryption.js';

let baseUrl = Config.baseUrl
// let baseUrl = 'http://192.168.0.88:8081/v1'
if(process.env.NODE_ENV == 'production') {
    // console.log('接口地址', process.env.VUE_APP_API_BASE_URL)
    baseUrl = process.env.VUE_APP_API_BASE_URL
}

// 创建 axios 实例
const request = axios.create({
    baseURL: baseUrl,
    timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
    return Promise.reject(error)
}
const times = new Date().getTime();
// request interceptor
request.interceptors.request.use(config => {
    if(Config.apiSign) {
        if(config.method == "get") {
            config.params.signTime = times;
            // console.log('数据加密get',config.params,sign(config.params || {}))
            config.params ={sign:encryptedData(sign(config.params || {}))}
            // config.params = sign(config.params || {})
        }
        else {
            config.data.signTime = times;
            // console.log('数据加密post',config.data,sign(config.data || {}))
            config.data = {sign:encryptedData(sign(config.data || {}))}
            // config.data = sign(config.data || {})
        }
    }
    const token = storage.get(ACCESS_TOKEN)
    if (token) {config.headers['Authorization'] = 'Bearer ' + token}
    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
    // if(){}console.log
    if(typeof response.data == 'string'){
        let info = decryptedData(response.data);
        response.data = JSON.parse(info);
    }
    console.log('resdata: ',response.data)
    const code = response.data.code
    if (code === 0) {
        return response.data
    }
    else if (code === 8) {
        if(response.config.url != 'auth/login') {
            notification.error({
                message: '提示',
                description: response.data.msg
            })
            const userInfo = storage.get(USER_INFO)
            return window.router.replace({path:'/account/reset', query: {account: userInfo.account || ''}})
        }
        return response.data
    }
    else if (code === 9) {
        store.commit('SET_TOKEN', '')
        storage.remove(ACCESS_TOKEN)
        notification.error({
            message: '提示',
            description: response.data.msg
        })
        return window.router.replace('/account/login')
    }
    else {
        return Promise.reject(response.data);
    }
    // return Promise.resolve(response);
    // return Promise.reject(response);
}, errorHandler)

const installer = {
    vm: {},
    install (Vue) {
        Vue.use(VueAxios, request)
    }
}

export default request

export {
    installer as VueAxios,
    request as axios
}
