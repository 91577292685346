/**
 * 项目默认配置项
 * primaryColor - 默认主题色, 如果修改颜色不生效，请清理 localStorage
 * navTheme - sidebar theme ['dark', 'light'] 两种主题
 * colorWeak - 色盲模式
 * layout - 整体布局方式 ['sidemenu', 'topmenu'] 两种布局
 * fixedHeader - 固定 Header : boolean
 * fixSiderbar - 固定左侧菜单栏 ： boolean
 * contentWidth - 内容区布局： 流式 |  固定
 *
 * storageOptions: {} - Vue-ls 插件配置项 (localStorage/sessionStorage)
 */
export let Config = {
  version: '1.0.1',  // 版本号
	apiSign: true,  // 是否数据加密
  // uploadUrl: 'http://192.168.0.249:1006/upload/v1',            // 陈俊霖本地上传git 
  // uploadUrl: 'http://192.168.0.223:1006/upload/v1',            // 邵成龙本地上传

	// baseUrl: 'http://192.168.0.224:9097/account/v1',          // 陶帅本地
	// baseUrl: 'http://192.168.0.224:9097/account/v1',          // 陶帅本地
	// baseUrl: 'http://192.168.0.225:1004/account/v1',          // 邵成龙本地
  // uploadUrl: 'http://192.168.0.223:1006/upload/v1',            // 邵成龙本地上传
	
	// baseUrl: 'http://192.168.0.240:1004/account/v1',          // 邵成龙本地

	// baseUrl: 'http://192.168.0.134:3101/account/v1',          // 陶帅本地
	baseUrl: 'http://192.168.0.238:1110/account/v1',          // 俊霖本地
	// baseUrl: 'http://192.168.0.223:1009/account/v1',          // 邵成龙本地
  // baseUrl: 'http://192.168.0.158:1103/account/v1',          // 佛颂本地
	// baseUrl: 'https://accounts.myshoppowerful.com/account/v1',   // 正式环境
}
if(process.env.NODE_ENV == 'production') {
  Config.uploadUrl = `${location.origin}/upload/v1/`;
  Config.baseUrl= `${location.origin}/account/v1/`;
}


export default {
  navTheme: 'dark', // theme for nav menu
  primaryColor: '#52C41A', // primary color of ant design
  layout: 'sidemenu', // nav menu position: `sidemenu` or `topmenu`
  contentWidth: 'Fluid', // layout of content: `Fluid` or `Fixed`, only works when layout is topmenu
  fixedHeader: false, // sticky header
  fixSiderbar: false, // sticky siderbar
  colorWeak: false,
  menu: {
    locale: true
  },
  title: 'Cool Store',
  pwa: false,
  iconfontUrl: '',
  production: process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true'
}
