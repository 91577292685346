<template>
  <!-- <a-result
    :isSuccess="true"
    :content="false"
    :title="email"
    :sub-title="description">

    <template #extra>
      <a-button v-loading-btn size="large" type="primary">查看邮箱</a-button>
      <a-button v-loading-btn size="large" style="margin-left: 8px" @click="goHomeHandle">返回首页</a-button>
    </template>

  </a-result> -->
  <div>
    <input
      id="Button1"
      type="button"
      value="浏览器所有信息"
      name="Button1"
      language="javascript"
      @click="allinfo"
    />

    <div id="elInfo"></div>
  </div>
</template>

<script>
// var locator = new ActiveXObject("WbemScripting.SWbemLocator")
// var service = locator.ConnectServer(".")
export default {
  name: "RegisterResult",
  data() {
    return {
      description:
        "激活邮件已发送到你的邮箱中，邮件有效期为24小时。请及时登录邮箱，点击邮件中的链接激活帐户。",
      form: {},
    };
  },
  computed: {
    email() {
      const v = (this.form && this.form.email) || "xxx";
      return `你的账户：${v} 注册成功`;
    },
  },
  created() {
    this.form = this.$route.params;
  },
  mounted() {

  },
  methods: {
    allinfo() {
      var appName = window.navigator.appName; //浏览器的正式名称
      var appVersion = navigator.appVersion; //浏览器的版本号
      var userAgent = navigator.userAgent; //包含以下属性中所有或一部分的字符串：appCodeName,appName,appVersion,language,platform

      var info = "<table border=1>";
      
      info += "<tr><td>浏览器属性信息：</td><td>" + userAgent + "</td></tr>";
      info += "<tr><td>浏览器的名称：</td><td>" + this.getBrowser() + "</td></tr>";
      info += "<tr><td>浏览器的版本号：</td><td>" + appVersion + "</td></tr>";
      
      info += "</table>";
      document.getElementById("elInfo").innerHTML = info;
      return info;
    },
    //获取浏览器名称
    getBrowser(){
      var browser = {
        msie: false,
        firefox: false,
        opera: false,
        safari: false,
        chrome: false,
        netscape: false,
        appname: 'unknown',
        version: 0
      },
      ua = window.navigator.userAgent.toLowerCase();
      if (/(msie|firefox|opera|chrome|netscape)\D+(\d[\d.]*)/.test(ua)) {
      browser[RegExp.$1] = true;
      browser.appname = RegExp.$1;
      browser.version = RegExp.$2;
      } else if (/version\D+(\d[\d.]*).*safari/.test(ua)) {
      // safari
      browser.safari = true;
      browser.appname = 'safari';
      browser.version = RegExp.$2;
      }
      return browser.appname + '/' + browser.version;
    },
  },
};
</script>

<style scoped>
</style>
