import Vue from 'vue'
import Vuex from 'vuex'
import storage from 'store'
import {
  // i18n
  APP_LANGUAGE
} from '@/store/mutation-types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		token: '',
		info: {},
		lang: 'en-US',
		_antLocale: {}
  },
	getters: {
	  lang: state => state.lang,
	  token: state => state.token,
	  userInfo: state => state.info || {}
	},
  mutations: {
    [APP_LANGUAGE]: (state, lang, antd = {}) => {
      state.lang = lang
      state._antLocale = antd
      storage.set(APP_LANGUAGE, lang)
    },
		SET_TOKEN: (state, token) => {
		  state.token = token
		},
		SET_INFO: (state, info) => {
		  state.info = info
		},
		SET_LANGUAGE: (state, lang) => {
		  state.lang = lang
		},
  },
  actions: {
    setLang ({ commit }, lang) {
      return new Promise((resolve, reject) => {
        commit(APP_LANGUAGE, lang)
        loadLanguageAsync(lang).then(() => {
          resolve()
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
  modules: {
  }
})
