import Vue from 'vue'
import VueRouter from 'vue-router'
import {UserLayout} from '@/layouts'

Vue.use(VueRouter)

const RouteView = {
    name: 'RouteView',
    render: (h) => h('router-view')
}

const routes = [
    {
        path: '/',
        name: 'system',
        component: UserLayout,
        redirect: '/account',
        children: [
            {
                path: '/account',
                name: 'account',
                redirect: '/account/login',
                component: RouteView,
                meta: {title: '账户'},
                children: [
                    {
                        path: 'login',
                        name: 'login',
                        component: () => import(/* webpackChunkName: "account" */ '@/views/account/Login'),
                        meta: { title: '登录' }
                    },
                    {
                        path: 'register',
                        name: 'register',
                        component: () => import(/* webpackChunkName: "account" */ '@/views/account/Register'),
                        meta: { title: '注册' }
                    },
                    {
                        path: 'register-result',
                        name: 'registerResult',
                        component: () => import(/* webpackChunkName: "account" */ '@/views/account/RegisterResult'),
                        meta: { title: '注册成功' }
                    },
                    {
                        path: 'recover',
                        name: 'recover',
                        component: () => import(/* webpackChunkName: "account" */ '@/views/account/Recover'),
                        meta: { title: '忘记密码' }
                    },
                    {
                        path: 'reset',
                        name: 'reset',
                        component: () => import(/* webpackChunkName: "account" */ '@/views/account/Reset'),
                        meta: { title: '重置密码' }
                    }
                ]
            }
        ]
    }
]

const router = new VueRouter({  // hash  history
  mode: 'history',
  base: 'account',
  routes
})

export default router
